<template>
  <b-container class="px-lg-5">
    <b-button :to="{name: 'blog'}" variant="outline-primary" class="mt-5 mb-1">{{ $t('blog.back_to_blog') }}</b-button>
    <b-card :title="post.title" title-tag="h1" :img-src="post.image" :img-height="400">
      <b-card-sub-title>{{ moment(_.get(post, 'publishedAt.date')).format('DD.MM.YYYY') }}</b-card-sub-title>
      <div v-html="post.content" class="pt-2"></div>
      <template #footer>
        <div class="tags-container">
          <b-badge v-for="tag in post.tags" :key="tag.id">{{ tag.title }}</b-badge>
        </div>
      </template>
    </b-card>

    <div class="mb-5 row">
      <div class="col">
        <h4>{{ $t('blog.more_from_the_blog') }}</h4>
      </div>
      <blog-list :per-page="2" embedded :exclude="[post.id]"></blog-list>
    </div>
  </b-container>
</template>

<script>
import {
  BContainer,
  BCard,
  BCardSubTitle,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import {mapActions} from "vuex";
import BlogList from "@/views/blog/BlogList";
import _ from 'lodash'

export default {
  components: {
    BlogList,
    BContainer,
    BCard,
    BCardSubTitle,
    BBadge,
    BButton,
  },
  computed: {
    post() {
      return this.$store.state.blog.post
    }
  },
  created() {
    this.getPost({
      category_slug: this.$route.params.category_slug,
      post_slug: this.$route.params.slug
    })
  },
  methods: {
    ...mapActions('blog', ['getPost'])
  },
  watch: {
    $route: function (to) {
      this.getPost({
        category_slug: to.category_slug,
        post_slug: to.params.slug
      })
    }
  },
  metaInfo() {
    let title = this.post.metaTitle || this.post.title
    let image = this.post.image
    let description = _.get(this.post, 'metaDescription', _.get(this.post, 'shortDescription', ''))
    let keywords = _.get(this.post, 'metaKeywords', [])
    if (!keywords.length) {
      keywords = _.map(this.post.tags, tag => tag.title)
    }
    keywords = keywords.join(',')
    return {
      title: title,
      meta: [
        {vmid: 'keywords', property: 'keywords', content: keywords},
        {vmid: 'og:title', property: 'og:title', content: title + ' | ' + 'PriceX'},
        {vmid: 'og:image', property: 'og:image', content: image},
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        },
      ]
    }
  },
}
</script>
<style scoped>
.tags-container {
  display: inline-grid;
  column-gap: 5px;
  row-gap: 5px;
  grid-template-columns: repeat(auto-fill, 80px);
  width: 100%;
}
</style>
